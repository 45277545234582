import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../../components/checkout';


import Mike from '../../pics/students/mike.jpg'


const MikeStory = () => (


	<div className="white grid-1-3"
	>
	<Helmet

	title="Student's story: Mike Sullivan, a front-end developer at Green Power Monitor"
	meta={[
		{ name: 'description', content: 'Students story: Mike Sullivan, a front-end developer at Green Power Monitor' },
		{ name: 'keywords', content: 'Students story, Barcelona Code School' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
		/>
		<div>
		<h1>Student's story: Mike Sullivan</h1>
		<h2>Graduated from the Web / Mobile Development Bootcamp</h2>
		<h2>Job landed: front-end developer at Green Power Monitor, Barcelona</h2>
		</div>
		<div style={{
			marginTop: '2em'
		}}>

		<img 
		style={{
			float: 'left',
			width: '50%',
			marginRight: '1em',
		}}
		src={Mike} />
		
		<p>One of Barcelona’s newest residents, Michael, embraces change. After moving to Barcelona to be with his girlfriend, he decided that a career change was in order as well. A dedicated career in customer service and project management in the freight forwarding industry for nine years, a decision to try a completely different field lead him to sign up for the <a href="https://www.barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-bootcamp/">Web / Mobile Development Bootcamp</a>. He’s no stranger to the programming world though.</p> 
		<p><blockquote>“I have been learning to code from various free online resources… I had always been interested in how apps and websites worked, but previously didn’t have the time to look into it, now being without a job I had a lot of free time so decided that I was going to learn to code.” he confesses, and even setup his <a href="https://github.com/gitChimp88">Github profile</a> up before walking through Barcelona Code School’s doors.</blockquote> </p>

		<p>Michael has always been a self-motivated individual, “Over the years I have taught myself to play guitar, piano and drums”, but felt that he needed a push in the right direction. “I hope to be able to secure a job as a junior developer in Barcelona, and also look into freelancing”, talking about his future plans. </p>

		<p>The Web / Mobile Development Bootcamp certainly gave him the motivation and the challenge that he needed, as well as an idea of what it’s like to work as a developer.</p>

		<p><blockquote>“Getting the projects finished on time, sometimes I would spend longer than expected on a particular problem and then not have enough time to add extra features, so managing my own time was quite challenging”.</blockquote></p>

		<p>Among his other challenges, he expressed that he was concerned that he “… would be able to process everything taught in the course as its such a lot of information in such a short amount of time”. He learned to adapt and offers this advice to future programming students; “Make more of an effort to understand something fully before moving on the next thing, use the Feynman Technique for difficult concepts … Spend plenty of time prior to the course getting comfortable with HTML, CSS and  basic JavaScript, this will ensure that you get the most out of it. If you are finding concepts difficult or you are stuck on a particular problem, use a pen and paper to break the problem down in to easier parts”.</p>

		<p>The <a href="https://www.barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-bootcamp/">in-person JavaScript Bootcamp</a> provided him with much more support and guidance than online courses. </p>

		<p><blockquote>“Yes, for me it was cool to learn something that I’m interested in from experts, it was also nice to know that if you didn’t understand something or needed clarification that you could just ask a question and get quite a detailed response”.</blockquote></p>

		<p>He goes on to talk about his day-to-day experiences in Barcelona Code School. “It was good! Everyone was friendly and inclusive, the lessons are interesting and the instructors engaging”.</p>

		<p>His hard work, positive attitude, and drive surely paid off. He speaks to us of his recent achievement.</p>

		<p><blockquote>“I have recently been offered a job as a front-end developer so at first I will apply the skills there, while learning as much as I can from other professionals, perhaps in the future start some personal projects”.</blockquote></p>

		<p>We at Barcelona Code School certainly can’t wait to see what Michael will achieve next. </p>

		</div>
		</div>


		)

export default MikeStory



